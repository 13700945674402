
//
// bootstrap-taginput.scss
//

/* Bootstrap tagsinput */
.bootstrap-tagsinput {
    box-shadow: none;
    padding: 4px 7px 4px;
    width: 100%;
  
    .label-info {
      background-color: $primary;
      display: inline-block;
      font-size: 11px;
      margin: 3px 1px;
      padding: 0 5px;
      border-radius: 3px;
    }
}
// 
// ribbons.scss
//

.ribbon-box {
  position: relative;

  /* Ribbon */
  .ribbon {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    span {
      font-size: 10px;
      color: $white;
      text-align: center;
      line-height: 20px;
      transform: rotate(-45deg);
      width: 100px;
      display: block;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
      position: absolute;
      top: 19px;
      left: -21px;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 100%;
        z-index: -1;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 100%;
        z-index: -1;
        border-left: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }
  }

  @each $color, $value in $theme-colors {
    .ribbon-#{$color} {

      span {
        background: ($value);
  
        &:before {
          border-left: 3px solid darken(($value),15%);
          border-top: 3px solid darken(($value),15%);
        }
  
        &:after {
          border-right: 3px solid darken(($value),15%);
          border-top: 3px solid darken(($value),15%);
        }
      }
    }
  }
}

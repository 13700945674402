//
// Daterange
//

.daterangepicker {
    font-family: $font-family-base;
    
    th, td{
        padding: 5px;
    }

    td.active, td.active:hover, .ranges li.active {
        background-color: $primary;
    }
    .drp-buttons {
        .btn{
            font-weight: $font-weight-medium;
        }
    }
}
//
// clockpicker.scss
//

.clockpicker-popover {
    .popover-title{
        font-size: 16px;
        font-weight: $font-weight-semibold;
    }
    .btn-default {
        background-color: $primary;
        color: $white;
    }
}